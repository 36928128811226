import React from "react";
import P from "prop-types";
import {connect} from "react-redux";
import LazyLoad from 'react-lazyload';
import * as field from "../../shared/constant/field";
import sfLogo from "../../images/third-party/sf-logo.png";

class PathwardFooter extends React.Component {

    //We use setTimeOut to ensure the component at the bottom is rendered before attempting to scroll to it
    componentDidMount() {
        if (document.location.hash === '#funding_time') {
            setTimeout(() => {
                document.querySelector("#funding_time").scrollIntoView({ behavior: "smooth", block: "start" });
            }, 300);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="x-background_footer wrapper">
                    <div className="footer-content pr-lg-3 footer-text-color">
                        <p>
                            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, we are required to obtain, verify, and record information that identifies each person who opens an account. What this means for you: when you open an account, we may ask for your name, address, social security, or tax identification number, date of birth, and other information that will allow us to identify you. We may also ask to see your driver&apos;s license or other identifying information.
                        </p>
                        <p>
                            Pathward reserves the right to assess your creditworthiness and ability to pay periodically, which may impact how you use your Line of Credit.
                        </p>
                        <p>
                            <strong>Automated Clearing House (ACH) Payment Cut-off Times:</strong> Schedule non-recurring ACH payments by 6:00 pm ET one (1) business day before the date you wish your payment to be applied by contacting us. Processing of payments are subject to your bank's policies.
                        </p>

                        <p>
                            <sup>1</sup>Not all applications are approved; duration of approval process may vary. Credit limit may change and is subject to further verification criteria. <strong>Additionally, if you have received a pre-approved offer for a Pathward Line of Credit:</strong> this offer is not guaranteed if you do not continue to meet the criteria used to select you for this offer and you may also be subject to further verification criteria.  If you do not continue to qualify for the amount stated in your pre-approved offer, you may qualify for a different credit limit based on your verified information. To continue to qualify for this offer, you must, at minimum, have a personal bank account, phone number and email address with the ability to transact business electronically. You must also meet the income requirements and not have a Pathward Line of Credit account or pending application. Terms and conditions apply.
                        </p>
                        <p>
                            <div id="funding_time"/>
                            <sup>2</sup><strong>ACH Credit Cut-off Times:</strong> If approved, any advances requested before 2:30 pm ET Monday to Friday will typically be deposited into your bank account the same business day, otherwise funds will be deposited the next business day. All funding cut-off times are subject to change. The date and time funds are made available to you by your bank are subject to your bank&apos;s policies.
                        </p>
                        <p>
                            <sup>3</sup>You can request an advance at any time, so long as you have available credit, your account is in good standing, and you otherwise meet any criteria required to request advances from your account.
                        </p>
                    </div>

                    <div className="footer-sidebar">
                        <section className="p-3 pr-0 ml-lg-5">
                            <p className="footer-text-color text-center">
                                <span className="material-symbols-rounded footer-question-mark">help</span>
                                <b>
                                    &nbsp; Questions? We're here to help.
                                </b>
                            </p>
                            <div className="text-center">
                                <div>
                                    <button
                                        id="submit-login"
                                        type="submit"
                                        className="contact-us-button"
                                        onClick={e => {
                                            e.preventDefault();
                                            window.open("tel:"+ this.props.content.common.customerServicePhone, '_self');
                                        }}
                                    >
                                        <b>{this.props.content.common.customerServicePhone}</b>
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <button
                                        id="submit-login"
                                        type="submit"
                                        className="contact-us-button"
                                        onClick={e => {
                                            e.preventDefault();
                                            window.open("mailto:"+ this.props.content.common.customerServiceEmail, '_self');
                                        }}
                                    >
                                        <b>Email Us</b>
                                    </button>
                                </div>
                                <span className="customer-care-hours footer-text-color">
                                    <ul>
                                        <li>
                                            <b>Customer Care Hours: </b>
                                        </li>
                                        <li>Mon - Fri: 8 am - 9 pm (ET)</li>
                                        <li>Sat - Sun: 10 am - 6 pm (ET)</li>
                                    </ul>
                                </span>
                            </div>
                            <section className=""></section>
                        </section>
                    </div>

                    <div className="temporary-hr-footer d-md-none">
                        <hr className="x-footer-hr" />
                    </div>

                    <div className="footer">
                        <hr className="x-footer-hr" />
                        <section className="text-sm-center text-lg-left footer-links-color">
                            <a
                                className="pr-1 footer-links-color"
                                href={this.props.content.common.termsOfUseURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>
                                    <span>Terms of Use</span>
                                </small>
                            </a>
                            <span className="px-1 text-white">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.privacyPolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>
                                    <span>Pathward Privacy Policy</span>
                                </small>
                            </a>
                            <span className="px-1 text-white">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.CAPrivacyPolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>
                                    <span>CA Privacy Policy</span>
                                </small>
                            </a>
                            <span className="px-1 text-white">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.NoticeOfCollectionURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>
                                    <span>Notice of Collection of Personal Information</span>
                                </small>
                            </a>
                        </section>

                        <p className="footer-text-color text-sm-center text-lg-left mb-0 pt-2">
                            <small>Pathward<sup>&reg;</sup>, N.A. All rights reserved.</small>
                        </p>
                        <div>
                            <LazyLoad>
                                <img src={sfLogo} alt="gd-logo" id="sf-logo-img"/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PathwardFooter.propTypes = {
    tel: P.string
};

const mapStateToProps = state => {
    return {
        tel: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER],
        resourceCDN: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_CDN],
        resourceFolderName:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_FOLDERNAME],
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    };
};

PathwardFooter = connect(mapStateToProps)(PathwardFooter);

export default PathwardFooter;
