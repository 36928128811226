import * as field from '../../../../../../../../shared/constant/field'

export function isEmailVerificationRemaining(todoEmailVerification) {
    return todoEmailVerification != null && !todoEmailVerification[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}


export function isESigRemaining(todoEsig) {
    return todoEsig != null && !todoEsig[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isBankVerificationRemaining(todoBankVerification) {
    return todoBankVerification != null && !todoBankVerification[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isVerifyPaidOffPriorLoansRemaining(todoVerifyPaidoffPriorLoans) {
    return todoVerifyPaidoffPriorLoans != null && !todoVerifyPaidoffPriorLoans[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isVerifyPreviousLoanPaidOff(todoVerifyPreviousLoanPaidOff) {
    return todoVerifyPreviousLoanPaidOff != null && !todoVerifyPreviousLoanPaidOff[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isCollectPaymentInfo(todoCollectPaymentInfo) {
    return todoCollectPaymentInfo != null && todoCollectPaymentInfo;
}

export function isEmploymentVerificationRemaining(todoEmploymentVerification) {
    return todoEmploymentVerification != null && !todoEmploymentVerification[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isReferencesRemaining(todoReferences) {
    return todoReferences != null && !todoReferences[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isSpousalInformationRemaining(todoSpousalInformation) {
    return todoSpousalInformation != null && !todoSpousalInformation[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isOTPEnabled(userOTPInfo) {
    return userOTPInfo != null && userOTPInfo[field.ISOTPENABLED];
}

export function isIdentityVerificationRemaining(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION]
        && !actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isIdentityVerificationCompleted(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION]
        && !!actionItem[field.DASHBOARD.TODO_IDENTITY_VERIFICATION][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isKYCCompleted(actionItem) {
    return !!actionItem
        && !!actionItem[field.DASHBOARD.TODO_KYC]
        && !!actionItem[field.DASHBOARD.TODO_KYC][field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isActionItemRemaining(actionItem) {
    return actionItem != null && !actionItem[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}

export function isActionItemCompleted(actionItem) {
    return !!actionItem && !!actionItem[field.TODO_ROW.TODO_FIELD_COMPLETIONTIME];
}
