import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import * as field from "../shared/constant/field";
import * as route from "../shared/constant/route";
import Auth from "./auth/AuthPage";

import * as organizationActions from "../store/action/OrganizationActions";
import PushNotification, {setDataTags, setExternalUserId} from "./OneSignal/PushNotification";
import * as progressAction from "../store/action/ProgressAction";
import utility from "../models/Organization";
import * as constant from "../shared/constant/constant"
import * as tagConstants from "../shared/constant/tagConstants";

const Application = React.lazy(() => import("./application/ApplicationPage"));
const Reapply = React.lazy(() => import("./reapply/ReapplyPage"));
const TDCPage = React.lazy(() => import("./dashboard/tdc/TDCPage"))
const Dashboard = React.lazy(() => import("./dashboard/DashboardPage"));
const Offers = React.lazy(() => import("./offers/OfferPage"));

const theme = createMuiTheme({
    typography: {
        fontFamily: "inherit",
        fontSize: "inherit",
        useNextVariants: true
    }
});

class App extends React.Component {
    componentDidMount() {
        this.props.setPreviousPathname(this.props.history.location.pathname);
        this.props.getOrganizationConfig(this.props.organizationId);
        PushNotification(this.props.buildProfile);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.disableBackButtonForEmailVerification(prevProps);
        try {
            window.scroll(0, 0);
        } catch (e) {
            console.log("Scrolling to top is not working for this browser.");
        }
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.checkUserAuthentication(!!this.props.isAuthenticated);
        }
        if (prevProps.userId!==this.props[field.USERID]) {
            if (!!this.props[field.USERID])
                setExternalUserId(this.props[field.USERID].toString());
        }
    }

    checkUserAuthentication = (isAuthenticated) => {
        setDataTags({[tagConstants.IS_LOGIN]:isAuthenticated});
    }

    disableBackButtonForEmailVerification(prevProps) {
        if (prevProps.lastPathname !== this.props.history.location.pathname) {
            if (prevProps.lastPathname === route.OTP_EMAIL_LOGIN_PAGE
                || prevProps.lastPathname === route.APPLICATION_OTP_EMAIL_VERIFICATION
                || prevProps.lastPathname === route.DASHBOARD_OTP_EMAIL_VERIFICATION) {
                this.disableBackButton();
            } else {
                window.removeEventListener("popstate", this.disableBackButtonOnPopState);
            }

            this.props.setPreviousPathname(this.props.history.location.pathname);
        }
    }

    disableBackButton = () => {
        window.history.pushState(null, null, window.location.href);
        window.addEventListener("popstate", this.disableBackButtonOnPopState);
    };

    disableBackButtonOnPopState = () => {
        window.history.go(1);
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <React.Suspense fallback={<h1>Loading...</h1>}>
                    <Switch>
                        <Route path={`/${route.APPLICATION_BASE_ROUTE_PARAMETER}/:page`} component={Application} />
                        <Route path={route.DASHBOARD_TDC_PAGE} component={TDCPage} />
                        <Route path={`/${route.DASHBOARD_BASE_ROUTE_PARAMETER}`} component={Dashboard} />
                        <Route path={`/${route.REAPPLY_BASE_ROUTE_PARAMETER}/:page`} component={Reapply} />
                        {(utility.getOrganizationId() !== constant.FORA_ORGANIZATION_ID) && <Route path={`/${route.OFFERS_BASE_ROUTE_PARAMETER}`} component={Offers} />}
                        <Route component={Auth} />
                    </Switch>
                </React.Suspense>

                <ToastContainer className="x-toast" bodyClassName="x-toast__body" />
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizationId:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID],
        buildProfile:state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
        lastPathname:state[field.REDUCER_NODE_APP_PROGRESS][field.PROGRESSBAR.LAST_PATHNAME],
        isAuthenticated: state[field.REDUCER_NODE_AUTH][field.ISAUTHENTICATED],
        userId: state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.USERID]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationConfig: organizationId => dispatch(organizationActions.getOrganizationConfig(organizationId)),
        setPreviousPathname: url => dispatch(progressAction.setPreviousPathname(url))
    };
};

App = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default withRouter(App);
