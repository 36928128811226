import React from "react";
import P from "prop-types";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import * as fields from "../../../shared/constant/field";
import * as routes from "../../../shared/constant/route";
import * as constant from "../../../shared/constant/constant";
import * as routeutility from "../../../shared/utility/routeutility";
import * as utility from "../../../shared/utility/utility";
import * as _helpers from "../../../shared/helpers";
import {canReloan} from "../../../shared/utility/dashboardutility";
import * as authActions from "../../../store/action/AuthAction";
import * as trackingActions from "../../../store/action/trackingActions";
import * as reduxFormActionsActions from "../../../store/action/reduxFormActionsActions";
import _ from "lodash";

import NavBarLinks, {classes} from "./_NavBarLinks";
import * as field from "../../../shared/constant/field";

class Header extends React.Component {
    static propTypes = {
        isAuthenticated: P.bool.isRequired,
        status: P.string,
        onLogoutUser: P.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            navbarlinks_open: false
        };

        this.toggler = this.toggler.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
    }

    toggler() {
        this.setState({navbarlinks_open: !this.state.navbarlinks_open});
    }

    handleLogout() {
        this.props.destroyReduxForms();
        this.props.trackLogOut();
        this.props.onLogoutUser();
        this.toggler();
    }

    isLogoLink() {
        if (!_.isEmpty(this.props.lmsCode) && this.props.lmsCode !== constant.LMS_JAGLMS) {
            return false;
        }

        let path = this.props.location.pathname;
        if (
            path.indexOf(routes.APPLICATION_STATE_INFO) > -1 ||
            path.indexOf(routes.APPLICATION_CREATE_PASSWORD) > -1 ||
            path.indexOf(routes.APPLICATION_REJECT) > -1 ||
            path.indexOf(routes.APPLICATION_STATE_SELECTION) > -1
        ) {
            return true;
        } else if (path.indexOf(routes.APPLICATION_BASE_ROUTE_PARAMETER) > -1) {
            return false;
        } else {
            return true;
        }
    }

    handleLogoClick(e) {
        e.preventDefault();
        if (!_.isEmpty(this.props.lmsCode) && this.props.lmsCode !== constant.LMS_JAGLMS) {
            return;
        }

        if (canReloan(this.props.status)) {
            this.props.history.push(routes.DASHBOARD_VIEW_ACCOUNT_PAGE);
        } else if (this.isLogoLink()) {
            if (!this.props.isAuthenticated || this.props.location.pathname.indexOf(routes.APPLICATION_REJECT) > -1) {
                window.location.href = constant.PUBLIC_WEBSITE;
            } else {
                this.getLogoClickPage(this.props.location.pathname + this.props.location.search);
            }
        }
    }

    getLogoClickPage = (currentPage) => {
        if (utility.isNullOrUndefined(currentPage)) {
            return null;
        }
        if (currentPage.startsWith(routes.PAGE_DASHBOARD)) {
            return this.props.history.push(routes.DASHBOARD_VIEW_ACCOUNT_PAGE);
        } else {
            return this.props.history.push(currentPage);
        }
    }

    render() {
        const hideNavLinkPages = [this.props.history.location.pathname.indexOf(routes.CHAT_PAGE), this.props.history.location.pathname.indexOf(routes.FEEDBACK_FORM_PAGE), this.props.history.location.pathname.indexOf(routes.FEEDBACK_FORM_PAGE_SUBMITTED)];

        let onHiddenNavLinksPage = hideNavLinkPages.every(n  => n === -1);
        let logo = "https://cdn.moneykey.com/images/logo/brand.svg";
        let logoStyle = this.props.orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID || this.props.orgId === constant.CREDITFRESH_ORGANIZATION_ID ? "x-img__feb" :this.props.orgId === constant.FORA_ORGANIZATION_ID ?"x-img__canada": "x-img__brand";
        if (this.props.orgId === constant.MONEYKEY_PLUS_ORGANIZATION_ID)
        {
            logo = "https://cdn.moneykey.com/images/ccflow-logo.png";
        }
        else if (this.props.orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID || this.props.orgId === constant.CREDITFRESH_ORGANIZATION_ID)
        {
            logo = this.props.isAuthenticated ? (window.location.origin.includes("secureplus") || window.location.origin.includes("3009")) ? "https://cdn.creditfresh.com/img/cf-feb-logo.svg" : "https://cdn.creditfresh.com/img/brand.svg" : "https://cdn.creditfresh.com/img/creditfresh-logo.svg";
        } else if (this.props.orgId === constant.FORA_ORGANIZATION_ID) {
            logo = "https://cdn.foracredit.ca/web/canada-fora-logo.svg";
        } else if (this.props.orgId === constant.PATHWARD_ORGANIZATION_ID) {
            logo = "https://cdn.loc.pathward.com/web/images/pathward-main.png"
        }

        let path = this.props.location.pathname;
        if(path.indexOf("email-opt-out") > -1) {
            if(this.props.orgId === constant.CREDITFRESH_ORGANIZATION_ID) {
                logo = "https://cdn.creditfresh.com/img/brand.svg"
            }else if(this.props.orgId === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) {
                logo = "https://cdn.creditfresh.com/img/cf-feb-logo.svg"
            }
        }

        return (
            <div className="overflow-auto">
                <div
                    className="d-flex border shadow-sm py-2 px-2 rounded bg-white"
                    style={{width: "98%", margin: "1vw auto"}}
                >
                    <button
                        id="logo-button"
                        onClick={this.handleLogoClick}
                        style={{
                            cursor: this.isLogoLink() ? "pointer" : "default",
                            backgroundColor: "transparent",
                            border: "none"
                        }}
                    >
                        <img
                            className={logoStyle}
                            src={logo}
                            alt=""
                        />
                    </button>

                    <div className="x-header__navbar x-header__navbar_2">

                        {(this.props.isAuthenticated && this.props.hasToken) && (
                            <div>
                                <input
                                    id="-navbarlinks-"
                                    type="checkbox"
                                    className="d-none"
                                    checked={this.state.navbarlinks_open}
                                    onChange={this.toggler}
                                />
                                {!routeutility.isAnyOfApplicationPage(this.props.location.pathname) &&
                                    <div className="x-navbar__toggler">
                                        <label htmlFor="-navbarlinks-" id="-navbarlinks-toggler-">
                                            <div>
                                                <span/>
                                                <span/>
                                                <span/>
                                                <span/>
                                            </div>
                                        </label>
                                    </div> }
                                <div className={routeutility.isAnyOfApplicationPage(this.props.location.pathname) ? "x-header__links_2" : "x-header__links"}>
                                    <React.Fragment>
                                        {!routeutility.isAnyOfApplicationPage(this.props.location.pathname) &&
                                            onHiddenNavLinksPage && (
                                                <NavBarLinks
                                                    status={this.props.status}
                                                    isTXEpicImport={this.props.isTXEpicImport}
                                                    toggler={this.toggler}
                                                    productType={this.props.productType}
                                                    isRC={this.props.isRC}
                                                    isRAL={this.props.isRAL}
                                                    loanItems={this.props.loanItems}
                                                    nonWithdrawnLoanStatus={this.props.nonWithdrawnLoanStatus}
                                                    lmsCode={this.props.lmsCode}
                                                />
                                            )
                                        }


                                        <routeutility.XLink
                                            className={`${classes["nav-item"]} text-decoration-none`}
                                            onClick={this.handleLogout}
                                            to={routeutility.getApplicationLoginPage()}
                                        >
                                            <button className={_helpers.getNavButtonClassName()}>
                                                Log out
                                            </button>


                                        </routeutility.XLink>
                                    </React.Fragment>
                                </div>
                            </div>
                        )}

                        {!this.props.isAuthenticated &&
                            (this.props.location.pathname.indexOf(routes.LOGIN) < 0 &&
                                this.props.location.pathname.indexOf(routes.STATE_LOGIN) < 0) && (
                                <div className="x-header__links_2">
                                    <Link className={`${classes["nav-item"]} text-decoration-none`}
                                          to={routeutility.getLoginPage()}>
                                        <button className={_helpers.getNavButtonClassName()}>
                                            Log in
                                        </button>
                                    </Link>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth[fields.ISAUTHENTICATED],
        userId: state.auth[fields.USERID],
        status: state.loan[fields.LOAN.ACTIVE_LOAN_SUMMARY][fields.LOAN.LOAN_STATUS],
        productType: state[fields.REDUCER_NODE_LOAN][fields.LOAN.ACTIVE_LOAN][fields.LOAN.LOAN_TYPE],
        isRC: state[fields.REDUCER_NODE_APPLICATION][fields.USERAPPMISC][fields.ISRC],
        isRAL: state[fields.REDUCER_NODE_APPLICATION][fields.USERAPPMISC][fields.ISRAL],
        isTXEpicImport: state[fields.REDUCER_NODE_DASHBOARD][fields.DASHBOARD.IS_TX_EPIC_IMPORT],
        loanItems: state[fields.REDUCER_NODE_DASHBOARD][fields.DASHBOARD.HISTORY][fields.DASHBOARD.HISTORY_ITEMS] || [],
        orgId: state[fields.REDUCER_NODE_ORGANIZATION][fields.ORGANIZATION.CONFIG][fields.ORGANIZATION.ORGANIZATION_ID],
        nonWithdrawnLoanStatus:
            state[fields.REDUCER_NODE_LOAN][fields.LOAN.ACTIVE_LOAN_SUMMARY][fields.LOAN.NON_WITHDRAWN_LOAN_STATUS],
        lmsCode: state[fields.REDUCER_NODE_AUTH][fields.LMS_CODE],
        hasToken: state.auth[field.MKWEBAUTHTOKEN]
    };
};

const mapDispatchToProps = dispatch => ({
    onLogoutUser: () => dispatch(authActions.logoutAction()),
    trackLogOut: () => dispatch(trackingActions.userLoggedOut()),
    destroyReduxForms: () => dispatch(reduxFormActionsActions.destroyApplicationReduxForms())
});

Header = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default withRouter(Header);
