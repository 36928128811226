import _ from "lodash";

import states from "./data/states.json";
import provinces from "./data/provinces.json";
import * as constants from "./constant/constant";
import * as fieldtags from "./constant/fieldtags";
import * as incomeTypes from "../views/application/employmentinfo/constants/incomeTypes";
import * as paymentTypes from "../views/application/employmentinfo/constants/paymentTypes";
import * as choices from "../views/application/paymentschedule/components/constants/choices/payfrequency";
import * as employmentInfoConstants from "../views/application/employmentinfo/utils/employmentInfoConstants";
import Axios from "axios";
import * as employerNames from '../views/application/employmentinfo/constants/employerNames'
import * as utility from "../shared/utility/utility";

export function getStateCodeFromState(state) {
    let code = (_.find(states, o => o.name.toLowerCase() === state.toLowerCase()) || {})["shortform"] || null;
    if (code === null) {
        code = (_.find(provinces, o => o.name.toLowerCase() === state.toLowerCase()) || {})["shortform"] || null;
    }
    return code;
}

export function getStateFromStateCode(statecode) {
    let state = (_.find(states, {shortform: statecode}) || {})["name"] || null;
    if (state === null) {
        state = (_.find(provinces, {shortform: statecode}) || {})["name"] || null;
    }
    return state;
}

export function getStateServedValue(state) {
    return _.find(states, o => o.name.toLowerCase() === state.toLowerCase())["served"];
}

export function getButtonClassName(valid = true) {
    let common = "btn btn-lg border-0 font-weight-bolder";
    if (valid) {
        return `${common} btn--x-btn-fill-color text--x-btn-text-color`;
    }
    return `${common} btn--x-btn-inactive-fill-color text--x-btn-inactive-text-color`;
}

export function getActiveButtonClassName() {
    return "btn btn-lg border-0 font-weight-bolder btn--x-btn-fill-color text--x-btn-text-color";
}

export function getSecondaryButtonClassName(disabled = false) {
    let common = "btn btn-lg font-weight-bolder border-3"
    if (disabled) {
        return `${common} border--x-btn-secondary-inactive-border-color btn--x-white text--x-btn-secondary-inactive-border-color`;
    }
    return `${common} border--x-btn-secondary-border-color btn--x-btn-secondary-fill-color text--x-btn-secondary-text-color`;
}

export function getTertiaryButtonClassName(disabled = false) {
    let common = "btn btn-lg font-weight-bolder border-0"
    if (disabled) {
        return `${common} btn--x-btn-inactive-fill-color text--x-btn-inactive-text-color`;
    }
    return `${common} btn--x-btn-tertiary-fill-color text--x-btn-tertiary-text-color`;
}

export function getNavButtonClassName() {
    return "btn btn-nav border-0 btn--x-btn-fill-color text--x-btn-text-color font-weight-bolder";
}

export function getStatementButtonClassName(valid) {

    let common = "btn border-0 font-weight-bolder documents-page-button";

    if (valid) {
        return `${common} btn--x-btn-fill-color text--x-btn-text-color`;
    }

    return  `${common} btn--x-btn-inactive-fill-color text--x-btn-inactive-text-color invalid`;
}

export function getViewDocumentButtonClassName(valid) {
    return  "border-0 btn btn--x-btn-tertiary-fill-color documents-page-button font-weight-bolder text--x-btn-tertiary-text-color";
}

export function getSquishedButton()
{
    return "btn btn-squish py-1 px-4 border-3 border--x-primary-green border--x-blue-turquoise font-weight-bolder text-white";
}

export function getPaymentButtonClassName(valid, small = false, noStyle = false) {
    return "valid-payment";
}

export function getLabelClassName(valid, touched) {
    if (touched) {
        return valid ? "x-form-label x-is-valid valid" : "x-form-label x-is-invalid invalid";
    }
    return "x-form-label";
}

export function getOptionalFieldClassName(valid, touched) {
    if (touched) {
        return valid ? "x-form-control valid" : "x-form-control x-is-invalid invalid";
    }
    return "x-form-control";
}

export function getSelectLabelClassName(valid, touched) {
    if (touched) {
        return valid ? "x-form-control x-is-valid selection-options" : "x-form-control x-is-invalid selection-options";
    }
    return "x-form-control selection-options";
}

export function getOptionalSelectLabelClassName(valid, touched) {
    if (touched) {
        return valid ? "x-form-control selection-options x-is-valid valid" : "x-form-control x-is-invalid invalid selection-options";
    }
    return "x-form-control selection-options invalid";
}

export function getSelectionDropDownClassName(valid, touched) {
    if(touched) {
        return valid ? "x-form-control valid" : "x-form-control x-is-invalid invalid";
    }

    return "x-form-control";
}

export function getFieldClassName(valid, touched, isBorderOnly = false) {
    if (touched) {
        return valid ? (isBorderOnly ? "x-form-control x-is-valid-border valid" : "x-form-control x-is-valid valid") : "x-form-control x-is-invalid invalid";
    }
    return "x-form-control";
}

export function getPhoneNumberFieldClassName(valid) {

    if(!valid) {
        return "x-form-control x-is-invalid invalid";
    }
    return  "x-form-control";
}

export function getAddressLine2FieldClassName(valid, touched, error) {
    if (touched) {
        return valid ? "x-form-control x-is-valid valid" : error ? "x-form-control x-is-invalid invalid" : "x-form-control";
    }
    return "x-form-control";
}

export function getSelectionFieldClassName(value) {
    if (value) {
        return "x-form-control x-is-valid-border valid";
    }
    return "x-form-control";
}

export function getCheckboxFieldClassName() {
    return "x-form-checkbox";
}

export function getCheckboxFieldClassNameFora() {
    return "x-form-checkbox-small";
}

export function getCommonButtonClassName(selected) {
    let common = "btn shadow-md x-btn-y x-btn-y--lg mb-3 mr-3 x-mr--remove-on-last-child mr-lg-4 font-weight-bolder";
    if (selected) {
        return `${common} text--x-white selected`;
    }
    return `${common} text--x-black bg--x-mid-grey text--x-black bg--x-grey-light`;
}

export function getYesOrNoButtonClassName(selected) {
    let classes = getCommonButtonClassName(selected);
    return `x-btn-x x-btn-x--lg ${classes}`;
}
export function getVerifyEmailMessageClassName() {
    let common = "narrowtext";
    if (utility.getOrganizationId() === constants.PATHWARD_ORGANIZATION_ID) {
        return `${common} x-blue-disclaimer`;
    }
    return `${common} x-blue-disclaimer`;
}
//Script for url splitting
export function urlSplitting() {
    let _url = window.location.href;
    if (_url.split("?")[1]) {
        _url = _url.split("?")[1].split("&");
    }
    //assign url values to object: parsed
    let parsed = {};
    for (let i = 0; i < _url.length; i++) {
        parsed[_url[i].split("=")[0]] = _url[i].split("=")[1];
    }
    return parsed;
}

export function sendFianancerPixelPostBack(clickId, state) {
    const sum = state === "CA" ? 15 : 30;
    let url = `${constants.FINANCER_PIXEL_POSTBACK_URL}?clickid=${clickId}&sum=${sum}`;
    Axios.post(url, {clickId, sum})
        .then(result => console.log(result))
        .catch(err => console.log(err));
}

export const LIVING_SITUATION_OPTIONS = [
    {id: "select-option", text: "Select", value: "-1"},
    {id: fieldtags.LIVINGSITUATION_LIVING_FOR_0_YEARS, text: "less than 1 year", value: "0"},
    {id: fieldtags.LIVINGSITUATION_LIVING_FOR_3_YEARS, text: "1 - 5 years", value: "3"},
    {id: fieldtags.LIVINGSITUATION_LIVING_FOR_6_YEARS, text: "more than 5 years", value: "6"}
];

export const INCOME_SOURCE_OPTIONS = [
    {id: incomeTypes.EMPLOYED, text: "I'm employed", value: "E"},
    {id: incomeTypes.BENEFITS, text: "I receive benefits/other income", value: "O"},
    {id: incomeTypes.SELF_EMPLOYED, text: "I'm self-employed", value: "SE"}
];

export const PAYMENT_TYPE_OPTIONS = [
    {id: paymentTypes.DIRECT_DEPOSIT, text: "Direct Deposit", value: employmentInfoConstants.PAYMENT_TYPE__DIRECT_DEPOSIT},
    {id: paymentTypes.CASH, text: "Cash", value: employmentInfoConstants.PAYMENT_TYPE__CASH},
    {id: paymentTypes.PAPER_CHECK, text: "Paper Check", value: employmentInfoConstants.PAYMENT_TYPE__PAPER_CHECK}
];

export const CANADA_PAYMENT_TYPE_OPTIONS = [
    {id: paymentTypes.DIRECT_DEPOSIT, text: "Direct Deposit", value: employmentInfoConstants.PAYMENT_TYPE__DIRECT_DEPOSIT},
    {id: paymentTypes.PAPER_CHEQUE, text: "Paper Cheque", value: employmentInfoConstants.PAYMENT_TYPE__PAPER_CHEQUE},
    {id: paymentTypes.CASH, text: "Cash", value: employmentInfoConstants.PAYMENT_TYPE__CASH}
];

export const PAY_FREQUENCY_OPTIONS = [
    {id: choices.MONTHLY, text: "Once a month (Monthly)", value: choices.MONTHLY},
    {id: choices.BIWEEKLY, text: "Every other week (Bi-Weekly)", value: choices.BIWEEKLY},
    {id: choices.WEEKLY, text: "Once a week (Weekly)", value: choices.WEEKLY},
    {id: choices.SEMI_MONTHLY, text: "Twice a month (Semi-Monthly)", value: choices.SEMI_MONTHLY}
];

export const CHAT_HELP_OPTIONS = [
    {id: "select-option", text: "Select", value: ""},
    {id: "new-loan", text: "I am a new customer applying for a loan", value: "Chat_Csq17"},
    {id: "existing-loan", text: "I have questions about my existing loan", value: "Chat_Csq13"},
    {id: "returning-customer", text: "I am a returning customer applying for a loan", value: "Chat_Csq13"}
];

export const FEEDBACK_CATEGORY_OPTIONS = [
    {id: "select-option", text: "Select", value: ""},
    {id: "account-inquiries", text: "Account Inquiries", value: "Account Inquiries"},
    {id: "collections", text: "Collections", value: "Collections"},
    {id: "customer-service", text: "Customer Service", value: "Customer Service"},
    {id: "technical-issues", text: "Technical Issues", value: "Technical Issues"},
    {id: "suggestions", text: "Suggestions", value: "Suggestions"},
    {id: "other", text: "Other", value: "Other"}
];

export const getIncomeTypeValue = incometype => {
    switch (incometype) {
        case "P":
        case "U":
        case "S":
        case "O":
        case "V":
        case "D":
            return "O";
        case "SE":
            return "SE";
        default:
            return "E";
    }
};

export const BENEFIT_TYPE_OPTIONS = [
    {id: employmentInfoConstants.INCOME_TYPE__SOCIAL_SECURITY, text: "Social Security", value: "S"},
    {id: employmentInfoConstants.INCOME_TYPE__VETERAN_BENEFIT, text: "Veteran's Benefits", value: "V"},
    {id: employmentInfoConstants.INCOME_TYPE__PENSION, text: "Pension", value: "P"},
    {id: employmentInfoConstants.INCOME_TYPE__DISABILITY, text: "Disability", value: "D"},
    {id: employmentInfoConstants.INCOME_TYPE__OTHER, text: "Other", value: "O"}
];

export const CANADA_BENEFIT_TYPE_OPTIONS = [
    {id: employmentInfoConstants.INCOME_TYPE__CPP_OR_OTHER_PENSION, text: "CPP or other Pension", value: "P"},
    {id: employmentInfoConstants.INCOME_TYPE__DISABILITY, text: "Disability", value: "D"},
    {id: employmentInfoConstants.INCOME_TYPE__EMPLOYMENT_INSURANCE, text: "Employment Insurance", value: "S"}
];

const getEmployerNameFromBenefitType = benefitType => {
    switch (benefitType) {
        case "S":
            if (utility.isForaCredit()){
                return employerNames.EMPLOYER_EMPLOYMENT_INSURANCE;
            } else {
                return employerNames.EMPLOYER_SOCIAL_SECURITY;
            }
        case "V":
            return employerNames.EMPLOYER_VETERANS_BENEFITS;
        case "P":
            if (utility.isForaCredit()){
                return employerNames.EMPLOYER_CPP_OR_OTHER_PENSION;
            } else {
                return employerNames.EMPLOYER_PENSION;
            }
        case "D":
            return employerNames.EMPLOYER_DISABILITY;
        case "O":
            return employerNames.EMPLOYER_OTHER;
        default:
            throw new Error("benefit type not defined");
    }
}

export const getEmployerNameFromIncomeType = (incomeType, benefitType) => {
    switch (incomeType) {
        case "SE":
            return employerNames.EMPLOYER_SELF_EMPLOYED;

        default:
            return getEmployerNameFromBenefitType(benefitType);
    }
}

export const MARITAL_STATUS_OPTIONS = [
    {id: "select-option", text: "Marital Status", value: "-1"},
    {id: fieldtags.MARITAL_STATUS_SINGLE, text: "Single", value: "SINGLE"},
    {id: fieldtags.MARITAL_STATUS_LEGALLY_SEPARATED, text: "Legally Separated", value: "SEPARATED"},
    {id: fieldtags.MARITAL_STATUS_LEGALLY_MARRIED, text: "Legally Married", value: "MARRIED"}
];

export const STATEMENTS_FILTER_OPTIONS = function(distinctYearsinStatement) {
    let filterOptions = [{id: fieldtags.STATEMENT_FILTERS_LAST_90_DAYS, text: "Last 90 Days", value: "90DAYS"}];
    if(distinctYearsinStatement && distinctYearsinStatement.length !== 0) {
        distinctYearsinStatement.forEach(year => {
            filterOptions.push({
                id: `${fieldtags.STATEMENT_FILTERS_YEAR}-${year}`,
                text: `${year} Statements`,
                value: `${year}`
            });
        });
    }
    filterOptions.push({id: fieldtags.STATEMENT_FILTERS_SELECT_DATES, text: "Select Dates", value: "SELECTDATES"});
    return filterOptions;
}

export const PROVINCES = [
    {id: "select-option", text: "Select", value: ""},
    {id: "province-alberta", text: "Alberta", value: "Alberta"},
    {id: "province-british-columbia", text: "British Columbia", value: "British Columbia"},
    {id: "province-manitoba", text: "Manitoba", value: "Manitoba"},
    {id: "province-new-brunswick", text: "New Brunswick", value: "New Brunswick"},
    {id: "province-newfoundland-labrador", text: "Newfoundland and Labrador", value: "Newfoundland and Labrador"},
    {id: "province-northwest-territories", text: "Northwest Territories", value: "Northwest Territories"},
    {id: "province-nova-scotia", text: "Nova Scotia", value: "Nova Scotia"},
    {id: "province-nunavut", text: "Nunavut", value: "Nunavut"},
    {id: "province-ontario", text: "Ontario", value: "Ontario"},
    {id: "province-prince-edward-island", text: "Prince Edward Island", value: "Prince Edward Island"},
    {id: "province-quebec", text: "Quebec", value: "Quebec"},
    {id: "province-saskatchewan", text: "Saskatchewan", value: "Saskatchewan"},
    {id: "province-yukon", text: "Yukon", value: "Yukon"}
];

export const RESIDENCY_STATUS = [
    {id: "select-option", text: "Select Status", value: ""},
    {id: "residency-status-canadian-citizen", text: "Canadian Citizen", value: "Canadian Citizen"},
    {id: "residency-status-permanent=resident", text: "Permanent Resident", value: "Permanent Resident"},
    {id: "residency-status-other", text: "Other", value: "Other"}
];
