import OneSignal from 'react-onesignal';
import * as constant from "../../shared/constant/constant";
import * as constants from "../../shared/constant/constant";
import * as utility from "../../shared/utility/utility";
import * as route from "../../shared/constant/route";
import {OFFERS_PAGE} from "../../shared/constant/route";
import * as tagConstants from "../../shared/constant/tagConstants";
import * as onesignalTemplate from "../../shared/constant/onesignalTemplate";

let isExternalIdExist = false;

function PushNotification(profile) {
    let appId = getOnesignalAppId(profile);
    if (appId != null) {
        OneSignal.init({
            appId: appId
        }).then(response => {
            console.log(response);
        });
    }
    OneSignal.getUserId().then((response) => {
        if (!utility.isNullOrUndefined(response)) {
            localStorage.setItem("playerId", response);
        }
    });
}

export const setExternalUserId = (userId) => {
    OneSignal.setExternalUserId(userId);
}

export const getExternalUserId = async () => {
    await OneSignal.getExternalUserId().then(function (externalUserId) {
        if (externalUserId)
            isExternalIdExist = true;
    });
    return isExternalIdExist;


}
export const setDataTags = (dataTagInfo) => {
    OneSignal.sendTags(dataTagInfo).then(response => {
    });
}
export const getPlayerId = () => {
    return localStorage.getItem("playerId");
}
export const setTagsForStartApplication = () => {
    OneSignal.getTags().then(response => {
        if ((window.location.pathname.startsWith(route.STATE_LOGIN) || window.location.pathname.startsWith(route.APPLICATION_LOGIN_PAGE))
            && utility.isNullOrUndefined(response)) {
            setDataTags({[tagConstants.TAG_APPLICATION_STARTED]: false,[tagConstants.TAG_IS_USER_CREATED_PASSWORD]:false});
        }
        if (window.location.pathname.startsWith(route.APPLICATION_STATE_SELECTION_PAGE) || window.location.pathname.startsWith(route.APPLICATION_START_APP_PAGE)
            || window.location.pathname.startsWith(OFFERS_PAGE))
            setDataTags({[tagConstants.TAG_APPLICATION_STARTED]: true});
    });
}

export const setTagsWhileCreatePasswordOrLogin = (createdPassword, userId) => {
    let dataTags = {};
    dataTags[tagConstants.TAG_IS_USER_CREATED_PASSWORD] = true;
    dataTags[tagConstants.TAG_USER_ID] = userId;
    setDataTags(dataTags);
}

export const getOnesignalAppId = (profile) => {
    let appId = '';
    if (profile === "production")
        appId = getProdAPIKey();
    else {
        appId = constants.ONESIGNANL_QA_APPID[utility.getOrganizationId()];
    }
    return appId;
}
export const getRestAPIKey = (profile) => {
    let restAPIKey = '';
    if (profile === "production") {
        restAPIKey = onesignalTemplate.PROD_REST_API_KEYS[utility.getOrganizationId()];
    } else {
        restAPIKey = onesignalTemplate.QA_REST_API_KEYS[utility.getOrganizationId()];
    }
    return restAPIKey;
}

export const getTemplateKey = (profile, templateName) => {
    if (profile === "production") {
        switch (templateName) {
            case onesignalTemplate.DRAW_REMINDER_SEGMENT:
                return onesignalTemplate.PROD_DRAW_REMINDER_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.PAYMENT_DUE_DATE_SEGMENT:
                return onesignalTemplate.PROD_PAYMENT_DUE_DATE_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.PAID_OFF_SEGMENT:
                return onesignalTemplate.PROD_PAID_OFF_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.WITHDRAWN_SEGMENT:
                return onesignalTemplate.PROD_WITHDRAWN_TEMPLATE_IDS[utility.getOrganizationId()];
            default:
                console.log("invalid production template name: " + templateName);
        }
    } else {
        switch (templateName) {
            case onesignalTemplate.DRAW_REMINDER_SEGMENT:
                return onesignalTemplate.QA_DRAW_REMINDER_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.PAYMENT_DUE_DATE_SEGMENT:
                return onesignalTemplate.QA_PAYMENT_DUE_DATE_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.PAID_OFF_SEGMENT:
                return onesignalTemplate.QA_PAID_OFF_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.WITHDRAWN_SEGMENT:
                return onesignalTemplate.QA_WITHDRAWN_TEMPLATE_IDS[utility.getOrganizationId()];
            case onesignalTemplate.COMPLETE_APPLICATION:
                return onesignalTemplate.QA_COMPLETE_APPLICATION_TEMPLATE_IDS[utility.getOrganizationId()];
            default:
                console.log("invalid template name: " + templateName);
        }
    }
    return "";
}

function getProdAPIKey() {
    switch (utility.getOrganizationId()) {
        case constant.MONEYKEY_ORGANIZATION_ID:
            return constant.MONEYKEY_ORGANIZATION_APP_ID;
        case constant.MONEYKEY_PLUS_ORGANIZATION_ID:
            return constant.MONEYKEY_PLUS_ORGANIZATION_APP_ID;
        case constant.CREDITFRESH_ORGANIZATION_ID:
            return constant.CREDITFRESH_ORGANIZATION_APP_ID;
        case constant.CREDITFRESH_PLUS_ORGANIZATION_ID:
            return constant.CREDITFRESH_PLUS_ORGANIZATION_APP_ID;
        case constant.FORA_ORGANIZATION_ID:
            return constant.FORA_ORGANIZATION_APP_ID;
        case constant.PATHWARD_ORGANIZATION_ID:
            return constant.PATHWARD_ORGANIZATION_APP_ID;
        default:
            console.log("invalid org id");
    }
    return "";
}

export default PushNotification;
